<template>
  <b-card-body>
    <b-row>
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="chatSearch.keyword"
            placeholder="Firma Adı, Katalog Adı Arama..."
            @keydown.enter="searchData"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchData"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </b-card-body>
</template>

<script>
import {
  BCardBody, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  name: 'IndexHeader',
  components: {
    BCardBody, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  },
  props: {
    searchData: {
      type: Function,
      required: true,
    },
  },
  computed: {
    chatSearch() {
      return this.$store.getters['chatMessages/searchData']
    },
  },
}
</script>
